import React from 'react'

import PropTypes from 'prop-types'

import './hydro-care-africa-steps.css'

const HydroCareAfricaSteps = (props) => {
  return (
    <div
      className={`hydro-care-africa-steps-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="hydro-care-africa-steps-max-width thq-section-max-width">
        <div className="hydro-care-africa-steps-container1 thq-grid-2">
          <div className="hydro-care-africa-steps-section-header">
            <h2 className="thq-heading-2">Discover the Hydro Care Advantage</h2>
            <p className="hydro-care-africa-steps-text01 thq-body-large">
              With a focus on customer satisfaction, our professional cleaning
              company offers a wide range of cleaning services for residential,
              office, commercial, and industrial spaces. We create tailored
              cleaning plans and provide exterior cleaning services to meet your
              specific needs. Our quality service is reflected in the
              testimonials from satisfied clients. Contact us today for
              inquiries or to schedule a service through the provided contact
              information and social media links.
            </p>
            <div className="hydro-care-africa-steps-actions">
              <button className="thq-button-animated thq-button-filled hydro-care-africa-steps-button">
                <a
                  href="tel:+254 750 966 684"
                  className="hydro-care-africa-steps-text02 thq-body-small"
                >
                  Call us now
                </a>
              </button>
            </div>
          </div>
          <div className="hydro-care-africa-steps-container2">
            <div className="hydro-care-africa-steps-container3 thq-card">
              <h2 className="thq-heading-2">{props.step1Title}</h2>
              <span className="hydro-care-africa-steps-text04 thq-body-small">
                {props.step1Description}
              </span>
              <label className="hydro-care-africa-steps-text05 thq-heading-3">
                01
              </label>
            </div>
            <div className="hydro-care-africa-steps-container4 thq-card">
              <h2 className="thq-heading-2">{props.step2Title}</h2>
              <span className="hydro-care-africa-steps-text07 thq-body-small">
                {props.step2Description}
              </span>
              <label className="hydro-care-africa-steps-text08 thq-heading-3">
                02
              </label>
            </div>
            <div className="hydro-care-africa-steps-container5 thq-card">
              <h2 className="thq-heading-2">{props.step3Title}</h2>
              <span className="hydro-care-africa-steps-text10 thq-body-small">
                {props.step3Description}
              </span>
              <label className="hydro-care-africa-steps-text11 thq-heading-3">
                03
              </label>
            </div>
            <div className="hydro-care-africa-steps-container6 thq-card">
              <h2 className="thq-heading-2">{props.step4Title}</h2>
              <span className="hydro-care-africa-steps-text13 thq-body-small">
                {props.step4Description}
              </span>
              <label className="hydro-care-africa-steps-text14 thq-heading-3">
                04
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HydroCareAfricaSteps.defaultProps = {
  step4Description:
    'We prioritize customer satisfaction and ensure that your space is left sparkling clean to meet your expectations.',
  step1Title: 'Contact Us',
  step2Title: 'Customized Cleaning Plan',
  step4Title: 'Customer Satisfaction',
  step3Description:
    'Our experienced team will arrive on time and provide high-quality cleaning services using industry-standard equipment.',
  rootClassName: '',
  step2Description:
    'We will create a tailored cleaning plan based on your requirements and the size of the space to be cleaned.',
  step3Title: 'Professional Cleaning Service',
  step1Description:
    'Reach out to us via phone or email to discuss your cleaning needs and schedule a free quote.',
}

HydroCareAfricaSteps.propTypes = {
  step4Description: PropTypes.string,
  step1Title: PropTypes.string,
  step2Title: PropTypes.string,
  step4Title: PropTypes.string,
  step3Description: PropTypes.string,
  rootClassName: PropTypes.string,
  step2Description: PropTypes.string,
  step3Title: PropTypes.string,
  step1Description: PropTypes.string,
}

export default HydroCareAfricaSteps
