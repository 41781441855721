import React from 'react'

import PropTypes from 'prop-types'

import './home-office-cleaning.css'

const HomeOfficeCleaning = (props) => {
  return (
    <div className="home-office-cleaning-layout349 thq-section-padding">
      <div className="home-office-cleaning-max-width thq-section-max-width">
        <div className="home-office-cleaning-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
        <div className="home-office-cleaning-content">
          <div className="home-office-cleaning-section-title">
            <div className="home-office-cleaning-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <p className="thq-body-large">{props.feature1Description}</p>
            </div>
            <div className="home-office-cleaning-actions"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeOfficeCleaning.defaultProps = {
  feature1Action1: 'Residential & Office Cleaning',
  feature1Title: 'Residential & Office Cleaning',
  feature1Slogan: 'Crystal Clear Views',
  feature1Description:
    'We are a professional cleaning company providing reliable and high-quality cleaning services to homes and offices of all sizes.  Our team of trained and experienced cleaners is dedicated to ensuring that your space is clean, hygienic, and presentable at all times.  We also offer washroom restocking and kitchen area management services to keep your home and office fully stocked and functional.',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI4fHxob21lJTIwY2xlYW5pbmclMjB8ZW58MHx8fHwxNzIwMTE3MTY4fDA&ixlib=rb-4.0.3&w=1400',
  feature1ImageAlt: 'Window Cleaning Image',
  feature1Action2: 'Learn More',
}

HomeOfficeCleaning.propTypes = {
  feature1Action1: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1Slogan: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1Action2: PropTypes.string,
}

export default HomeOfficeCleaning
