import React from 'react'

import PropTypes from 'prop-types'

import './gallery-contact-us.css'

const GalleryContactUs = (props) => {
  return (
    <div className="gallery-contact-us-gallery3 thq-section-padding">
      <div className="gallery-contact-us-max-width thq-section-max-width"></div>
      <div className="gallery-contact-us-content">
        <div className="gallery-contact-us-container">
          <img
            alt={props.image1Alt}
            src={props.image1Src}
            className="thq-img-ratio-4-3"
          />
        </div>
        <div className="gallery-contact-us-container1">
          <img
            alt={props.image2Alt}
            src={props.image2Src}
            className="thq-img-ratio-4-3"
          />
        </div>
        <div className="gallery-contact-us-container2">
          <img
            alt={props.image3Alt}
            src={props.image3Src}
            className="thq-img-ratio-4-3"
          />
        </div>
      </div>
    </div>
  )
}

GalleryContactUs.defaultProps = {
  image2Src:
    'https://images.unsplash.com/photo-1482449609509-eae2a7ea42b7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHx3aW5kb3clMjBjbGVhbnxlbnwwfHx8fDE3MjAxMTY0Mjh8MA&ixlib=rb-4.0.3&w=1500',
  content1:
    'Check out our expert exterior cleaning services in action. From pristine windows to spotless gutters, we deliver top-notch results for residential, commercial, and industrial properties.',
  image2Alt: 'Pressure Washing Service',
  heading1: 'Gallery',
  image3Src:
    'https://images.unsplash.com/photo-1529220502050-f15e570c634e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3OTIyN3w&ixlib=rb-4.0.3&q=80&w=1500',
  image3Alt: 'Gutter Cleaning Service',
  image1Src:
    'https://images.unsplash.com/photo-1593125606494-1672550eb193?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDZ8fHNwcmF5JTIwd2FzaHxlbnwwfHx8fDE3MjAxMTY3MjZ8MA&ixlib=rb-4.0.3&w=1500',
  image1Alt: 'Window Cleaning Service',
}

GalleryContactUs.propTypes = {
  image2Src: PropTypes.string,
  content1: PropTypes.string,
  image2Alt: PropTypes.string,
  heading1: PropTypes.string,
  image3Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default GalleryContactUs
