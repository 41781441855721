import React from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

import "./style.css";
import Services from "./views/services";
import About from "./views/about";
import ContactUs from "./views/contact-us";
import Home from "./views/home";
import NotFound1 from "./views/not-found";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route component={Services} exact path="/services" />
                <Route component={About} exact path="/about" />
                <Route component={ContactUs} exact path="/contact-us" />
                <Route component={Home} exact path="/" />
                <Route component={NotFound1} exact path="/not-found" />
                <Route component={NotFound1} path="**" />
                <Redirect to="**" />
            </Switch>
        </Router>
    );
};

ReactDOM.render(<App />, document.getElementById("app"));
