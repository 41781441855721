import React from "react";

import { Helmet } from "react-helmet";

import NaviHeader from "../components/navi-header";
import FrontGalary from "../components/front-galary";
import HomeOfficeCleaning from "../components/home-office-cleaning";
import CTA from "../components/cta";
import CommercialCleaning from "../components/commercial-cleaning";
import HydroCareAfricaSteps from "../components/hydro-care-africa-steps";
import KeyClients from "../components/key-clients";
import Testimonial from "../components/testimonial";
import Contact from "../components/contact";
import Footer from "../components/footer";
import "./home.css";

const Home = (props) => {
    return (
        <div className="home-container">
            <Helmet>
                <title>Hydro Care Africa</title>
            </Helmet>
            <NaviHeader
                link1="Home"
                link2="Sercices"
                link3="Contact"
                link4="About "
                logoSrc={process.env.PUBLIC_URL + "/hca pro cropped.jpg"}
            ></NaviHeader>
            <FrontGalary rootClassName="front-galary-root-class-name"></FrontGalary>
            <HomeOfficeCleaning></HomeOfficeCleaning>
            <CTA></CTA>
            <CommercialCleaning></CommercialCleaning>
            <HydroCareAfricaSteps rootClassName="hydro-care-africa-steps-root-class-name"></HydroCareAfricaSteps>
            <div className="home-container1 thq-section-padding">
                <KeyClients></KeyClients>
            </div>
            <Testimonial></Testimonial>
            <Contact></Contact>
            <Footer rootClassName="footer-root-class-name"></Footer>
        </div>
    );
};

export default Home;
