import React from "react";

import PropTypes from "prop-types";

import "./key-clients.css";

const KeyClients = (props) => {
    return (
        <div className="key-clients-container thq-section-padding">
            <div className="key-clients-max-width thq-section-max-width">
                <h2 className="key-clients-text thq-heading-2">
                    {props.heading1}
                </h2>
                <div className="thq-grid-5">
                    <img
                        alt={props.logo1Alt}
                        src={props.logo1Src}
                        className="key-clients-logo1 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo2Alt}
                        src={props.logo2Src}
                        className="key-clients-logo2 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo3Alt}
                        src={props.logo3Src}
                        className="key-clients-logo3 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo4Alt}
                        src={props.logo4Src}
                        className="key-clients-logo4 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo5Alt}
                        src={props.logo5Src}
                        className="key-clients-logo5 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo6Alt}
                        src={props.logo6Src}
                        className="key-clients-logo6 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo7Alt}
                        src={props.logo7Src}
                        className="key-clients-logo7 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo8Alt}
                        src={props.logo8Src}
                        className="key-clients-logo8 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo9Alt}
                        src={props.logo9Src}
                        className="key-clients-logo9 thq-img-ratio-16-9"
                    />
                    <img
                        alt={props.logo100Alt}
                        src={props.logo100Src}
                        className="key-clients-logo10 thq-img-ratio-16-9"
                    />
                </div>
            </div>
        </div>
    );
};

KeyClients.defaultProps = {
    logo3Src: "/external/Happy Clients/cfk-1400w.png",
    logo9Alt: "Logo",
    logo7Alt: "Logo",
    logo4Alt: "Solar Cleaning Services logo",
    logo2Alt: "Pressure Washing Services logo",
    logo8Src: "/external/Happy Clients/zeiben-1400w.png",
    logo6Alt: "Logo",
    logo10Src:
        "https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original",
    logo10Alt: "Logo",
    logo1Alt: "Hydro Care Africa logo",
    logo4Src: "/external/Happy Clients/brand%20immersion-1400w.png",
    logo3Alt: "Gutter Cleaning Services logo",
    logo5Src: "/external/Happy Clients/auto%20movers-1400w.png",
    logo6Src: "/external/Happy Clients/hifadhi-1400w.png",
    logo7Src: "/external/Happy Clients/dosan%20ltd-1400w.png",
    heading1: "Our Customers",
    logo9Src: "/external/Happy Clients/hillstec-1400w.jpg",
    logo2Src: "/external/Happy Clients/food%20vibes%202-1400w.png",
    logo1Src: "/external/Happy Clients/nairobi%20street%20kitchen-1400w.png",
    logo5Alt: "Christmas Light Installation logo",
    logo8Alt: "Logo",

    logo100Alt: "Distar School",
    logo100Src: "/Distar.png",
};

KeyClients.propTypes = {
    logo100Alt: PropTypes.string,
    logo100Src: PropTypes.string,
    logo3Src: PropTypes.string,
    logo9Alt: PropTypes.string,
    logo7Alt: PropTypes.string,
    logo4Alt: PropTypes.string,
    logo2Alt: PropTypes.string,
    logo8Src: PropTypes.string,
    logo6Alt: PropTypes.string,
    logo10Src: PropTypes.string,
    logo10Alt: PropTypes.string,
    logo1Alt: PropTypes.string,
    logo4Src: PropTypes.string,
    logo3Alt: PropTypes.string,
    logo5Src: PropTypes.string,
    logo6Src: PropTypes.string,
    logo7Src: PropTypes.string,
    heading1: PropTypes.string,
    logo9Src: PropTypes.string,
    logo2Src: PropTypes.string,
    logo1Src: PropTypes.string,
    logo5Alt: PropTypes.string,
    logo8Alt: PropTypes.string,
};

export default KeyClients;
