import React from 'react'

import { Helmet } from 'react-helmet'

import NaviHeader from '../components/navi-header'
import OurServicesHeader from '../components/our-services-header'
import ResidentialCleaning from '../components/residential-cleaning'
import CommercialCleaning1 from '../components/commercial-cleaning-1'
import './services.css'

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services - Hydro Care Africa</title>
        <meta
          name="description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta property="og:title" content="Services - Hydro Care Africa" />
        <meta
          property="og:description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c840b007-abfe-4060-a3ca-4bd25b96ce6a/2d9cae6d-01a1-49b5-9660-25cf136583d2?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <NaviHeader rootClassName="navi-header-root-class-name"></NaviHeader>
      <OurServicesHeader></OurServicesHeader>
      <ResidentialCleaning></ResidentialCleaning>
      <CommercialCleaning1 rootClassName="commercial-cleaning1-root-class-name"></CommercialCleaning1>
    </div>
  )
}

export default Services
