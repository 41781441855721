import React from 'react'

import { Helmet } from 'react-helmet'

import NaviHeader from '../components/navi-header'
import AboutHydrocare from '../components/about-hydrocare'
import Timeline from '../components/timeline'
import Statistica from '../components/statistica'
import MeetTheTeam from '../components/meet-the-team'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Hydro Care Africa</title>
        <meta
          name="description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta property="og:title" content="About - Hydro Care Africa" />
        <meta
          property="og:description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c840b007-abfe-4060-a3ca-4bd25b96ce6a/2d9cae6d-01a1-49b5-9660-25cf136583d2?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <NaviHeader
        link2="Services"
        rootClassName="navi-header-root-class-name2"
      ></NaviHeader>
      <AboutHydrocare></AboutHydrocare>
      <Timeline></Timeline>
      <Statistica></Statistica>
      <MeetTheTeam></MeetTheTeam>
      <footer className="about-footer1 thq-section-padding">
        <div className="about-max-width thq-section-max-width">
          <div className="about-content">
            <div className="about-links">
              <div className="about-column1">
                <strong className="thq-body-large about-column1-title">
                  Services
                </strong>
                <div className="about-footer-links">
                  <span className="thq-body-small">Residential Cleaning</span>
                  <span className="thq-body-small">Office Cleaning</span>
                  <span className="thq-body-small">Hotel Cleaning</span>
                  <span className="thq-body-small">Commercial Cleaning</span>
                  <span className="thq-body-small">Industrial Cleaning</span>
                </div>
              </div>
              <div className="about-column2">
                <strong className="thq-body-large about-column2-title">
                  Company
                </strong>
                <div className="about-footer-links1">
                  <span className="thq-body-small">About Us</span>
                  <span className="thq-body-small">Contact Us</span>
                  <span className="thq-body-small">Careers</span>
                  <span className="thq-body-small">FAQs</span>
                  <span className="thq-body-small">Blog</span>
                </div>
              </div>
              <div className="about-column3">
                <strong className="thq-body-large about-social-link1-title">
                  Connect with Us
                </strong>
                <div className="about-social-links">
                  <div className="about-link">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                    </svg>
                    <a
                      href="https://www.facebook.com/hydrocarafrica"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="about-social-link1 thq-body-small"
                    >
                      Facebook
                    </a>
                  </div>
                  <div className="about-link1">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                    <a
                      href="https://www.instagram.com/hydro_care_africa/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="about-social-link2 thq-body-small"
                    >
                      Instagram
                    </a>
                  </div>
                  <div className="about-link2">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <span className="thq-body-small">LinkedIn</span>
                  </div>
                  <div className="about-container1">
                    <div className="about-link3">
                      <svg className="thq-icon-small">
                        <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                      </svg>
                      <span className="thq-body-small">Youtube</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default About
