import React from 'react'

import PropTypes from 'prop-types'

import './commercial-cleaning.css'

const CommercialCleaning = (props) => {
  return (
    <div className="commercial-cleaning-layout349 thq-section-padding">
      <div className="commercial-cleaning-max-width thq-section-max-width">
        <div className="commercial-cleaning-content">
          <div className="commercial-cleaning-section-title">
            <div className="commercial-cleaning-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <p className="thq-body-large">{props.feature1Description}</p>
            </div>
            <div className="commercial-cleaning-actions"></div>
          </div>
        </div>
        <div className="commercial-cleaning-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="commercial-cleaning-placeholder-image thq-img-ratio-16-9"
          />
        </div>
      </div>
    </div>
  )
}

CommercialCleaning.defaultProps = {
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1595392312189-486fd4156898?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3NTYwNnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Title: 'Commercial & Industrial Cleaning Services',
  feature1ImageAlt: 'Exterior Cleaning Services',
  feature1Description:
    'At Hydro Care Africa we pride ourselves on the capacity to  effectively and safely wash commercial properties, warehouses, and industrial buildings, cleaning of  all sorts to get rid of years of dirt or mold  buildup whether it is above ground, exterior of  the building or underground garage.',
}

CommercialCleaning.propTypes = {
  feature1ImageSrc: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1Description: PropTypes.string,
}

export default CommercialCleaning
