import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer
      className={`footer-footer1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer-max-width thq-section-max-width">
        <div className="footer-content">
          <div className="footer-links">
            <div className="footer-column1">
              <strong className="thq-body-large footer-column1-title">
                {props.column1Title}
              </strong>
              <div className="footer-footer-links">
                <span className="thq-body-small">{props.link1}</span>
                <span className="thq-body-small">{props.link2}</span>
                <span className="thq-body-small">{props.link3}</span>
                <span className="thq-body-small">{props.link4}</span>
                <span className="thq-body-small">{props.link5}</span>
              </div>
            </div>
            <div className="footer-column2">
              <strong className="thq-body-large footer-column2-title">
                {props.column2Title}
              </strong>
              <div className="footer-footer-links1">
                <span className="thq-body-small">{props.link6}</span>
                <span className="thq-body-small">{props.link7}</span>
                <span className="thq-body-small">{props.link8}</span>
                <span className="thq-body-small">{props.link9}</span>
                <span className="thq-body-small">{props.link10}</span>
              </div>
            </div>
            <div className="footer-column3">
              <strong className="thq-body-large footer-social-link1-title">
                {props.socialLinkTitleCategory}
              </strong>
              <div className="footer-social-links">
                <div className="footer-link">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path
                      d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
                      className=""
                    ></path>
                  </svg>
                  <a
                    href="https://www.facebook.com/hydrocarafrica"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-social-link1 thq-body-small"
                  >
                    Facebook
                  </a>
                </div>
                <div className="footer-link1">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path
                      d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                      className=""
                    ></path>
                  </svg>
                  <a
                    href="https://www.instagram.com/hydro_care_africa/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-social-link2 thq-body-small"
                  >
                    Instagram
                  </a>
                </div>
                <div className="footer-link2">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path
                      d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                      className=""
                    ></path>
                  </svg>
                  <span className="thq-body-small">LinkedIn</span>
                </div>
                <div className="footer-container">
                  <div className="footer-link3">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="footer-icon6"
                    >
                      <path
                        d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
                        className=""
                      ></path>
                    </svg>
                    <span className="thq-body-small">Whatsapp</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  link8: 'Careers',
  content2:
    'Hydro Care Africa is a cleaning company that offers a wide range of cleaning services for residential, office, hotel, commercial, and industrial spaces. Our specialty lies in exterior cleaning services such as window cleaning, pressure washing, gutter cleaning, solar panel cleaning, and Christmas light installation.',
  socialLinkTitleCategory: 'Connect with Us',
  link7: 'Contact Us',
  cookiesLink: '/cookie-policy',
  link6: 'About Us',
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  link4: 'Commercial Cleaning',
  link9: 'FAQs',
  link2: 'Office Cleaning',
  rootClassName: '',
  pastedImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c840b007-abfe-4060-a3ca-4bd25b96ce6a/3ef7e189-a3fe-497a-a950-7b649aee3adc?org_if_sml=137936&force_format=original',
  logoAlt: 'Hydro Care Africa Logo',
  link3: 'Hotel Cleaning',
  link10: 'Blog',
  column1Title: 'Services',
  column2Title: 'Company',
  action1: 'Book Now',
  privacyLink: '/privacy-policy',
  link1: 'Residential Cleaning',
  termsLink: '/terms-and-conditions',
  link5: 'Industrial Cleaning',
  content3:
    'Address: [Insert Address], Phone: [Insert Phone Number], Email: [Insert Email]',
  pastedImageAlt: 'pastedImage',
}

Footer.propTypes = {
  link8: PropTypes.string,
  content2: PropTypes.string,
  socialLinkTitleCategory: PropTypes.string,
  link7: PropTypes.string,
  cookiesLink: PropTypes.string,
  link6: PropTypes.string,
  logoSrc: PropTypes.string,
  link4: PropTypes.string,
  link9: PropTypes.string,
  link2: PropTypes.string,
  rootClassName: PropTypes.string,
  pastedImageSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  link3: PropTypes.string,
  link10: PropTypes.string,
  column1Title: PropTypes.string,
  column2Title: PropTypes.string,
  action1: PropTypes.string,
  privacyLink: PropTypes.string,
  link1: PropTypes.string,
  termsLink: PropTypes.string,
  link5: PropTypes.string,
  content3: PropTypes.string,
  pastedImageAlt: PropTypes.string,
}

export default Footer
