import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './front-galary.css'

const FrontGalary = (props) => {
  return (
    <div className={`front-galary-header78 ${props.rootClassName} `}>
      <div className="front-galary-column thq-section-max-width thq-section-padding">
        <div className="front-galary-content">
          <h1 className="front-galary-text thq-heading-1">{props.heading1}</h1>
          <p className="front-galary-text1 thq-body-large">{props.content1}</p>
        </div>
      </div>
      <div className="front-galary-content1">
        <div className="front-galary-row-container thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="front-galary-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="front-galary-placeholder-image01 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="front-galary-placeholder-image02 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="front-galary-placeholder-image03 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="front-galary-placeholder-image04 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="front-galary-placeholder-image05 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="front-galary-placeholder-image06 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="front-galary-placeholder-image07 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="front-galary-placeholder-image08 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="front-galary-placeholder-image09 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="front-galary-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="front-galary-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
        <div className="front-galary-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="front-galary-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="front-galary-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="front-galary-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="front-galary-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="front-galary-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="front-galary-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="front-galary-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="front-galary-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="front-galary-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="front-galary-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="front-galary-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="front-galary-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="front-galary-container1">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

FrontGalary.defaultProps = {
  image8Src:
    'https://images.unsplash.com/photo-1588416820614-f8d6ac6cea56?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGNvbW1lcmNpYWwlMjBraXRjaGVufGVufDB8fHx8MTcyMDExODYwOXww&ixlib=rb-4.0.3&w=1500',
  image4Alt: 'Gutter Cleaning',
  heading1: 'Professional Cleaning Services in Kenya',
  rootClassName: '',
  image12Alt: 'Hero Image',
  image10Src:
    'https://images.unsplash.com/photo-1501891037204-8754d498396b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0MzAxN3w&ixlib=rb-4.0.3&q=80&w=1080',
  image9Alt: 'Hero Image',
  content1:
    'Hydro Care Africa offers top-notch exterior cleaning services for residential, office, hotel, commercial, and industrial properties in Kenya. From window cleaning to pressure washing, gutter cleaning, solar cleaning, and even Christmas light installation, we have you covered.',
  image10Alt: 'Hero Image',
  image11Src:
    'https://images.unsplash.com/photo-1488998527040-85054a85150e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0Mjk5Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  image6Src:
    'https://images.unsplash.com/photo-1542785291-fe3faea39066?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0MzAxMXw&ixlib=rb-4.0.3&q=80&w=1080',
  image12Src:
    'https://images.unsplash.com/photo-1521737711867-e3b97375f902?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0MzAxM3w&ixlib=rb-4.0.3&q=80&w=1080',
  image6Alt: 'Christmas Light Installation',
  image3Alt: 'Pressure Washing',
  image3Src:
    'https://images.unsplash.com/photo-1527515637462-cff94eecc1ac?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxzZXdlciUyMGNsZWFuaW5nfGVufDB8fHx8MTcyMDExODUzMnww&ixlib=rb-4.0.3&w=1500',
  image7Alt: 'Hero Image',
  image11Alt: 'Hero Image',
  image1Src:
    'https://images.unsplash.com/photo-1601160458000-2b11f9fa1a0e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fG9mZmljZSUyMGNsZWFuaW5nfGVufDB8fHx8MTcyMDExODQ2NXww&ixlib=rb-4.0.3&w=1500',
  image2Alt: 'Window Cleaning',
  image2Src:
    'https://images.unsplash.com/photo-1595332997730-1cc9c994f652?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYzfHx3aW5kb3clMjBjbGVhbnxlbnwwfHx8fDE3MjAxMTY0MzZ8MA&ixlib=rb-4.0.3&w=1500',
  image7Src:
    'https://images.unsplash.com/photo-1603712725038-e9334ae8f39f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIwfHxzZXdlciUyMGNsZWFuaW5nfGVufDB8fHx8MTcyMDExODUzM3ww&ixlib=rb-4.0.3&w=1500',
  image9Src:
    'https://images.unsplash.com/photo-1659175409523-24bcf1d03426?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE4fHxsZWFmJTIwYmxvd2VyfGVufDB8fHx8MTcyMDExODcxMHww&ixlib=rb-4.0.3&w=1500',
  image8Alt: 'Hero Image',
  image1Alt: 'Exterior Cleaning Services',
  image5Src:
    'https://images.unsplash.com/photo-1523289333742-be1143f6b766?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0MzAxNnw&ixlib=rb-4.0.3&q=80&w=1080',
  image4Src:
    'https://images.unsplash.com/photo-1487446929682-f62f73984006?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk0MzAxN3w&ixlib=rb-4.0.3&q=80&w=1080',
  image5Alt: 'Solar Panel Cleaning',
}

FrontGalary.propTypes = {
  image8Src: PropTypes.string,
  image4Alt: PropTypes.string,
  heading1: PropTypes.string,
  rootClassName: PropTypes.string,
  image12Alt: PropTypes.string,
  image10Src: PropTypes.string,
  image9Alt: PropTypes.string,
  content1: PropTypes.string,
  image10Alt: PropTypes.string,
  image11Src: PropTypes.string,
  image6Src: PropTypes.string,
  image12Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image11Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image7Src: PropTypes.string,
  image9Src: PropTypes.string,
  image8Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image4Src: PropTypes.string,
  image5Alt: PropTypes.string,
}

export default FrontGalary
