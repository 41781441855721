import React from 'react'

import PropTypes from 'prop-types'

import './residential-cleaning.css'

const ResidentialCleaning = (props) => {
  return (
    <div className="residential-cleaning-layout300 thq-section-padding">
      <div className="residential-cleaning-max-width thq-section-max-width thq-flex-column">
        <div className="residential-cleaning-section-title thq-flex-column">
          <label className="residential-cleaning-text thq-heading-2">
            {props.sectionTitle}
          </label>
          <p className="residential-cleaning-text1 thq-body-large">
            {props.sectionDescription}
          </p>
        </div>
        <div className="residential-cleaning-content thq-grid-auto-300">
          <div className="residential-cleaning-feature1 thq-flex-column">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="residential-cleaning-feature1-title thq-heading-3">
              {props.feature1Title}
            </h3>
            <span className="thq-body-small">{props.feature1Description}</span>
          </div>
          <div className="residential-cleaning-feature2 thq-flex-column">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature2Title}</h3>
            <span className="thq-body-small">{props.feature2Description}</span>
          </div>
          <div className="residential-cleaning-feature3 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature3Title}</h3>
            <span className="thq-body-small">{props.feature3Description}</span>
          </div>
        </div>
        <div className="residential-cleaning-actions thq-flex-row"></div>
      </div>
    </div>
  )
}

ResidentialCleaning.defaultProps = {
  feature3Description:
    'Thorough removal of debris and buildup from gutters to prevent water damage and maintain proper drainage.',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1511650364779-c32bcecc064f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3MzU0MXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Description:
    'Utilizing high-pressure water spray to remove dirt, grime, mold, and other contaminants from surfaces.',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1698348185977-99522a45d234?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3MzU0MHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageAlt: 'Gutter Cleaning Image',
  sectionTitle: 'Residential Cleaning',
  feature2ImageAlt: 'Window Cleaning Image',
  feature2Description:
    'Expert cleaning of windows to ensure a streak-free shine and enhanced curb appeal.',
  sectionDescription:
    'A clean, well-kept home says a lot about its owner. A properly maintained house offers off a desirable impression.',
  feature2Title: 'Window Cleaning',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1465417001205-3e465bfad702?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3MzU0MHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1ImageAlt: 'Pressure Washing Image',
  feature3Title: 'Gutter Cleaning',
  feature1Title: 'Pressure Washing',
}

ResidentialCleaning.propTypes = {
  feature3Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature1Description: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  sectionTitle: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature2Description: PropTypes.string,
  sectionDescription: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature3Title: PropTypes.string,
  feature1Title: PropTypes.string,
}

export default ResidentialCleaning
