import React from 'react'

import PropTypes from 'prop-types'

import './cta.css'

const CTA = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="thq-section-max-width">
        <div className="cta-accent2-bg">
          <div className="cta-accent1-bg">
            <div className="cta-container1">
              <div className="cta-content">
                <span className="thq-heading-2">{props.heading1}</span>
                <p className="thq-body-large">{props.content1}</p>
              </div>
              <div className="cta-actions">
                <a
                  href="mailto:cleanme@hydrocare.africa?subject=Quotation"
                  className="cta-link thq-button-filled"
                >
                  {props.action1}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  heading1: 'Ready to give your property a fresh look?',
  action1: 'Get a Quote',
  content1:
    'Contact us today to schedule your exterior cleaning services and make your property shine.',
}

CTA.propTypes = {
  heading1: PropTypes.string,
  action1: PropTypes.string,
  content1: PropTypes.string,
}

export default CTA
