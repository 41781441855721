import React from 'react'

import PropTypes from 'prop-types'

import './commercial-cleaning-1.css'

const CommercialCleaning1 = (props) => {
  return (
    <div
      className={`commercial-cleaning-1-layout300 thq-section-padding ${props.rootClassName} `}
    >
      <div className="commercial-cleaning-1-max-width thq-section-max-width thq-flex-column">
        <div className="commercial-cleaning-1-section-title thq-flex-column">
          <h2 className="thq-heading-2 commercial-cleaning-1-text">
            {props.sectionTitle}
          </h2>
          <p className="commercial-cleaning-1-text1 thq-body-large">
            {props.sectionDescription}
          </p>
        </div>
        <div className="commercial-cleaning-1-content thq-grid-auto-300">
          <div className="commercial-cleaning-1-feature1 thq-flex-column">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="commercial-cleaning-1-feature1-title thq-heading-3">
              {props.feature1Title}
            </h3>
            <span className="thq-body-small">{props.feature1Description}</span>
          </div>
          <div className="commercial-cleaning-1-feature2 thq-flex-column">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature2Title}</h3>
            <span className="thq-body-small">{props.feature2Description}</span>
          </div>
          <div className="commercial-cleaning-1-feature3 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature3Title}</h3>
            <span className="thq-body-small">{props.feature3Description}</span>
          </div>
          <div className="commercial-cleaning-1-feature4 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature3Title}</h3>
            <span className="thq-body-small">{props.feature3Description}</span>
          </div>
          <div className="commercial-cleaning-1-feature41 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature3Title}</h3>
            <span className="thq-body-small">{props.feature3Description}</span>
          </div>
          <div className="commercial-cleaning-1-feature42 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">{props.feature3Title}</h3>
            <span className="thq-body-small">{props.feature3Description}</span>
          </div>
        </div>
        <div className="commercial-cleaning-1-actions thq-flex-row"></div>
      </div>
    </div>
  )
}

CommercialCleaning1.defaultProps = {
  feature2ImageAlt: 'Window Cleaning Image',
  mainAction: 'Contact Us for a Free Quote',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1637970067784-927e66e07e36?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwfHxnYXJhZ2V8ZW58MHx8fHwxNzIwMDg2MDQ4fDA&ixlib=rb-4.0.3&w=1400',
  rootClassName: '',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1582489853490-cd3a53eb4530?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3MzU2NHw&ixlib=rb-4.0.3&q=80&h=1000',
  feature1Title: 'Roof & Gutter Cleanin',
  feature1Description:
    'Utilizing high-pressure water to remove dirt, grime, mold, and other contaminants from various surfaces.',
  secondaryAction: 'Learn More About Our Services',
  feature3Title: 'Gutter Cleaning',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1669101602108-fa5ba89507ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDA3MzU2NXw&ixlib=rb-4.0.3&q=80&h=1000',
  feature1ImageAlt: 'Pressure Washing Image',
  feature3ImageAlt: 'Gutter Cleaning Image',
  sectionTitle: 'COMMERCIAL CLEANING',
  feature3Description:
    'Efficient removal of debris and blockages from gutters to prevent water damage and maintain proper drainage.',
  sectionDescription:
    'Discover the top features of our professional exterior cleaning services.',
  feature2Title: 'Window Cleaning',
  feature2Description:
    'Thorough cleaning of windows to ensure a streak-free, crystal-clear view both inside and out.',
}

CommercialCleaning1.propTypes = {
  feature2ImageAlt: PropTypes.string,
  mainAction: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1Description: PropTypes.string,
  secondaryAction: PropTypes.string,
  feature3Title: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  sectionTitle: PropTypes.string,
  feature3Description: PropTypes.string,
  sectionDescription: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2Description: PropTypes.string,
}

export default CommercialCleaning1
