import React from 'react'

import { Helmet } from 'react-helmet'

import NaviHeader from '../components/navi-header'
import GalleryContactUs from '../components/gallery-contact-us'
import Footer from '../components/footer'
import './contact-us.css'

const ContactUs = (props) => {
  return (
    <div className="contact-us-container">
      <Helmet>
        <title>Contact-Us - Hydro Care Africa</title>
        <meta
          name="description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta property="og:title" content="Contact-Us - Hydro Care Africa" />
        <meta
          property="og:description"
          content="Hydro Care Africa Premier cleaning services for residential, commercial and industries. Experience spotless spaces with our expert team. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c840b007-abfe-4060-a3ca-4bd25b96ce6a/2d9cae6d-01a1-49b5-9660-25cf136583d2?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <NaviHeader rootClassName="navi-header-root-class-name1"></NaviHeader>
      <div className="contact-us-section-title">
        <span className="thq-body-small">
          Our team is available to assist you with any inquiries via phone or
          email during our business hours.
        </span>
        <div className="contact-us-content">
          <h2 className="thq-heading-2">Contact Us</h2>
          <p className="contact-us-text02 thq-body-large">
            We&apos;re here to handle all your cleaning needs with excellence
            and dedication! Reach out today for a free quote or any inquiries,
            and experience the ultimate in cleanliness and service. Let us make
            your space sparkle!
          </p>
        </div>
      </div>
      <GalleryContactUs></GalleryContactUs>
      <div className="contact-us-max-width thq-section-max-width">
        <div className="contact-us-row">
          <div className="contact-us-content1">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
            </svg>
            <div className="contact-us-contact-info">
              <div className="contact-us-content2">
                <h3 className="contact-us-text03 thq-heading-3">Email</h3>
                <p className="contact-us-text04 thq-body-large">
                  Feel free tor email for any questions or to schedule a
                  service.
                </p>
              </div>
              <a
                href="mailto:cleanme@hydrocare.africa?subject="
                className="contact-us-email thq-body-small"
              >
                Email here
              </a>
            </div>
          </div>
          <div className="contact-us-content3">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
            </svg>
            <div className="contact-us-contact-info1">
              <div className="contact-us-content4">
                <h3 className="contact-us-text05 thq-heading-3">Phone</h3>
                <p className="contact-us-text06 thq-body-large">
                  Let us help you keep your spaces clean and shining!
                </p>
              </div>
              <span className="contact-us-phone thq-body-small">
                +254 750 966 684
              </span>
            </div>
          </div>
          <div className="contact-us-content5">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
            </svg>
            <div className="contact-us-contact-info2">
              <div className="contact-us-content6">
                <h3 className="contact-us-text07 thq-heading-3">Office</h3>
                <p className="contact-us-text08 thq-body-large">
                  <span>Visit our office to schedule a service.</span>
                  <br></br>
                  <br></br>
                </p>
              </div>
              <span className="contact-us-address thq-body-small">
                Ngong Road, Nairobi
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ContactUs
