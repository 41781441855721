import React from 'react'

import PropTypes from 'prop-types'

import './timeline.css'

const Timeline = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="timeline-max-width thq-section-max-width">
        <div className="timeline-container01">
          <h2 className="thq-heading-2">{props.title1}</h2>
          <span className="timeline-text01 thq-body-small">
            {props.content1}
          </span>
          <div className="timeline-container02">
            <button type="button" className="thq-button-filled">
              {props.action1}
            </button>
            <button type="button" className="thq-button-outline">
              {props.action2}
            </button>
          </div>
        </div>
        <div className="timeline-timeline-container">
          <div className="timeline-step1">
            <div className="timeline-container03">
              <div className="timeline-container04"></div>
              <div className="timeline-progress0"></div>
            </div>
            <div className="timeline-container05">
              <h3 className="thq-heading-3">{props.date1}</h3>
              <h3 className="timeline-text03 thq-heading-3">
                {props.card1Heading}
              </h3>
              <span className="thq-body-small">{props.card1Content}</span>
              <div className="timeline-container06">
                <button type="button" className="thq-button-filled">
                  {props.card1Action1}
                </button>
                <button type="button" className="thq-button-outline">
                  {props.card1Action2}
                </button>
              </div>
            </div>
          </div>
          <div className="timeline-step2">
            <div className="timeline-container07">
              <div className="timeline-container08"></div>
              <div className="timeline-progress01"></div>
            </div>
            <div className="timeline-container09">
              <h3 className="thq-heading-3">{props.date2}</h3>
              <h3 className="timeline-text06 thq-heading-3">
                {props.card2Heading}
              </h3>
              <span className="thq-body-small">{props.card2Content}</span>
              <div className="timeline-container10">
                <button type="button" className="thq-button-filled">
                  {props.card2Action1}
                </button>
                <button type="button" className="thq-button-outline">
                  {props.card2Action2}
                </button>
              </div>
            </div>
          </div>
          <div className="timeline-step3">
            <div className="timeline-container11">
              <div className="timeline-container12"></div>
              <div className="timeline-progress02"></div>
            </div>
            <div className="timeline-container13">
              <h3 className="thq-heading-3">{props.date3}</h3>
              <h3 className="timeline-text09 thq-heading-3">
                {props.card3Heading}
              </h3>
              <span className="thq-body-small">{props.card3Content}</span>
              <div className="timeline-container14">
                <button type="button" className="thq-button-filled">
                  {props.card3Action1}
                </button>
                <button type="button" className="thq-button-outline">
                  {props.card3Action2}
                </button>
              </div>
            </div>
          </div>
          <div className="timeline-step4">
            <div className="timeline-container15">
              <div className="timeline-container16"></div>
              <div className="timeline-progress03"></div>
            </div>
            <div className="timeline-container17">
              <h3 className="thq-heading-3">{props.date4}</h3>
              <h3 className="timeline-text12 thq-heading-3">
                {props.card4Heading}
              </h3>
              <span className="thq-body-small">{props.card4Content}</span>
              <div className="timeline-container18">
                <button type="button" className="thq-button-filled">
                  {props.card4Action1}
                </button>
                <button type="button" className="thq-button-outline">
                  {props.card4Action2}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Timeline.defaultProps = {
  card3Action2: 'Contact Us',
  card2Content:
    'In response to growing demand, Hydro Care Africa expanded its services to include a wider range of exterior cleaning solutions.',
  date2: '2015',
  card4Heading: 'Commitment to Sustainability',
  card2Action2: 'Get a Quote',
  card3Content:
    'Hydro Care Africa received prestigious awards for its exceptional service quality and customer satisfaction.',
  date4: '2021',
  card1Heading: 'Founding of Hydro Care Africa',
  card2Action1: 'Explore Services',
  date3: '2018',
  action1: 'Primary action',
  card3Heading: 'Recognition for Excellence',
  content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  card1Action2: 'Contact Us',
  action2: 'Seconday action',
  card1Action1: 'Learn More',
  card2Heading: 'Diversification of Services',
  date1: '2010',
  card4Action2: 'Request Sustainability Info',
  card4Action1: 'Learn More',
  title1: 'Hydro Care Africa Established',
  card1Content:
    'Hydro Care Africa was founded with a vision to provide top-quality exterior cleaning services in Kenya.',
  card4Content:
    'Hydro Care Africa embraced eco-friendly practices to minimize environmental impact while delivering outstanding cleaning services.',
  card3Action1: 'View Awards',
}

Timeline.propTypes = {
  card3Action2: PropTypes.string,
  card2Content: PropTypes.string,
  date2: PropTypes.string,
  card4Heading: PropTypes.string,
  card2Action2: PropTypes.string,
  card3Content: PropTypes.string,
  date4: PropTypes.string,
  card1Heading: PropTypes.string,
  card2Action1: PropTypes.string,
  date3: PropTypes.string,
  action1: PropTypes.string,
  card3Heading: PropTypes.string,
  content1: PropTypes.string,
  card1Action2: PropTypes.string,
  action2: PropTypes.string,
  card1Action1: PropTypes.string,
  card2Heading: PropTypes.string,
  date1: PropTypes.string,
  card4Action2: PropTypes.string,
  card4Action1: PropTypes.string,
  title1: PropTypes.string,
  card1Content: PropTypes.string,
  card4Content: PropTypes.string,
  card3Action1: PropTypes.string,
}

export default Timeline
