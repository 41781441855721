import React from 'react'

import PropTypes from 'prop-types'

import './our-services-header.css'

const OurServicesHeader = (props) => {
  return (
    <div className="our-services-header-layout216 thq-section-padding">
      <div className="our-services-header-max-width thq-section-max-width thq-flex-row">
        <div className="thq-flex-column">
          <img
            alt={props.featureImageAlt}
            src={props.featureImageSrc}
            className="thq-img-ratio-4-3 our-services-header-image1"
          />
        </div>
        <div className="thq-flex-column our-services-header-content">
          <div className="our-services-header-section-title thq-flex-column">
            <h2 className="thq-heading-2">{props.sectionTitle}</h2>
            <p className="our-services-header-text1 thq-body-large">
              {props.featureDescription}
            </p>
          </div>
          <div className="our-services-header-actions thq-flex-row"></div>
        </div>
      </div>
    </div>
  )
}

OurServicesHeader.defaultProps = {
  sectionTitle: 'Our Services',
  secondaryAction: 'Learn More',
  featureDescription:
    'Hydro Care Africa provides a wide range of professional cleaning services tailored for residential, office, commercial, and industrial spaces. Their offerings include exterior cleaning services such as pressure washing, window cleaning, and gutter cleaning. With a focus on deep cleaning and specialized solutions, our expert team guarantees immaculate results for all types of properties.',
  featureImageSrc:
    'https://images.unsplash.com/photo-1675255057189-d2bc51eaa4bf?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYwfHxjb21tZXJjaWFsJTIwY2xlYW5pbmd8ZW58MHx8fHwxNzIwMTE4MTQzfDA&ixlib=rb-4.0.3&w=1400',
  featureImageAlt: 'PlaceholderImage1314',
}

OurServicesHeader.propTypes = {
  sectionTitle: PropTypes.string,
  secondaryAction: PropTypes.string,
  featureDescription: PropTypes.string,
  featureImageSrc: PropTypes.string,
  featureImageAlt: PropTypes.string,
}

export default OurServicesHeader
