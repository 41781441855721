import React from 'react'

import PropTypes from 'prop-types'

import './statistica.css'

const Statistica = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="statistica-max-width thq-section-max-width">
        <div className="statistica-container1">
          <h2 className="thq-heading-2 statistica-title">{props.heading1}</h2>
          <span className="thq-body-small">{props.content1}</span>
        </div>
        <div className="statistica-container2">
          <div className="statistica-container3">
            <h2 className="thq-heading-2">{props.stat1}</h2>
            <span className="thq-body-small statistica-text1">
              {props.stat1Description}
            </span>
          </div>
          <div className="statistica-container4">
            <h2 className="thq-heading-2">{props.stat2}</h2>
            <span className="thq-body-small statistica-text3">
              {props.stat2Description}
            </span>
          </div>
          <div className="statistica-container5">
            <h2 className="thq-heading-2">{props.stat3}</h2>
            <span className="thq-body-small statistica-text5">
              {props.stat3Description}
            </span>
          </div>
          <div className="statistica-container6">
            <h2 className="thq-heading-2">{props.stat4}</h2>
            <span className="thq-body-small statistica-text7">
              {props.stat4Description}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Statistica.defaultProps = {
  stat1: '99%',
  stat3: '99%',
  heading1: 'Business value of teleportHQ',
  content1: 'Our results in numbers',
  stat4Description: 'Active users',
  stat4: '44%',
  stat3Description: 'Customer satisfaction',
  stat2Description: 'Active users',
  stat2: '44%',
  stat1Description: 'Customer satisfaction',
}

Statistica.propTypes = {
  stat1: PropTypes.string,
  stat3: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  stat4Description: PropTypes.string,
  stat4: PropTypes.string,
  stat3Description: PropTypes.string,
  stat2Description: PropTypes.string,
  stat2: PropTypes.string,
  stat1Description: PropTypes.string,
}

export default Statistica
