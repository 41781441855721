import React from 'react'

import PropTypes from 'prop-types'

import './meet-the-team.css'

const MeetTheTeam = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="meet-the-team-max-width thq-section-max-width">
        <div className="meet-the-team-section-title">
          <span className="thq-body-small">{props.content1}</span>
          <div className="meet-the-team-content">
            <h2 className="meet-the-team-text01 thq-heading-2">
              {props.heading1}
            </h2>
            <p className="meet-the-team-text02 thq-body-large">
              {props.content2}
            </p>
          </div>
        </div>
        <div className="meet-the-team-content01">
          <div className="meet-the-team-card">
            <img
              alt={props.member1Alt}
              src={props.member1Src}
              className="meet-the-team-placeholder-image thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content02">
              <div className="meet-the-team-title">
                <span className="meet-the-team-text03 thq-body-small">
                  {props.member1}
                </span>
                <span className="meet-the-team-text04 thq-body-small">
                  {props.member1Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member1Content}</span>
            </div>
            <div className="meet-the-team-social-icons">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
          <div className="meet-the-team-card1">
            <img
              alt={props.member2Alt}
              src={props.member2Src}
              className="meet-the-team-placeholder-image1 thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content03">
              <div className="meet-the-team-title1">
                <span className="meet-the-team-text06 thq-body-small">
                  {props.member2}
                </span>
                <span className="meet-the-team-text07 thq-body-small">
                  {props.member2Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member2Content}</span>
            </div>
            <div className="meet-the-team-social-icons1">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
          <div className="meet-the-team-card2">
            <img
              alt={props.member3Alt}
              src={props.member3Src}
              className="meet-the-team-placeholder-image2 thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content04">
              <div className="meet-the-team-title2">
                <span className="meet-the-team-text09 thq-body-small">
                  {props.member3}
                </span>
                <span className="meet-the-team-text10 thq-body-small">
                  {props.member3Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member3Content}</span>
            </div>
            <div className="meet-the-team-social-icons2">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
          <div className="meet-the-team-card3">
            <img
              alt={props.member4Alt}
              src={props.member4Src}
              className="meet-the-team-placeholder-image3 thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content05">
              <div className="meet-the-team-title3">
                <span className="meet-the-team-text12 thq-body-small">
                  {props.member4}
                </span>
                <span className="meet-the-team-text13 thq-body-small">
                  {props.member4Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member4Content}</span>
            </div>
            <div className="meet-the-team-social-icons3">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
          <div className="meet-the-team-card4">
            <img
              alt={props.member5Alt}
              src={props.member5Src}
              className="meet-the-team-placeholder-image4 thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content06">
              <div className="meet-the-team-title4">
                <span className="meet-the-team-text15 thq-body-small">
                  {props.member5}
                </span>
                <span className="meet-the-team-text16 thq-body-small">
                  {props.member5Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member5Content}</span>
            </div>
            <div className="meet-the-team-social-icons4">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
          <div className="meet-the-team-card5">
            <img
              alt={props.member6Alt}
              src={props.member6Src}
              className="meet-the-team-placeholder-image5 thq-img-ratio-1-1 thq-img-round"
            />
            <div className="meet-the-team-content07">
              <div className="meet-the-team-title5">
                <span className="meet-the-team-text18 thq-body-small">
                  {props.member6}
                </span>
                <span className="meet-the-team-text19 thq-body-small">
                  {props.member6Job}
                </span>
              </div>
              <span className="thq-body-small">{props.member6Content}</span>
            </div>
            <div className="meet-the-team-social-icons5">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="meet-the-team-content08">
          <div className="meet-the-team-content09">
            <h2 className="meet-the-team-text21 thq-heading-2">
              {props.heading2}
            </h2>
            <span className="meet-the-team-text22 thq-body-small">
              {props.content3}
            </span>
          </div>
          <button className="meet-the-team-button thq-button-filled">
            <span className="thq-body-small">{props.actionContent}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

MeetTheTeam.defaultProps = {
  content2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  member4Job: 'Customer Relations Specialist',
  member2: 'Jane Smith',
  member3Alt: 'Image of David Johnson',
  member4Alt: 'Image of Sarah Brown',
  member2Src:
    'https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM1N3w&ixlib=rb-4.0.3&q=80&w=1080',
  member3Src:
    'https://images.unsplash.com/photo-1557862921-37829c790f19?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM1N3w&ixlib=rb-4.0.3&q=80&w=1080',
  member5Content:
    'Michael oversees the technical aspects of our services, ensuring efficiency and safety standards are maintained.',
  member6Job: 'Marketing Coordinator',
  heading2: 'We’re hiring!',
  member5Src:
    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM1N3w&ixlib=rb-4.0.3&q=80&w=1080',
  member5: 'Michael Lee',
  member1: 'John Doe',
  member3: 'David Johnson',
  member6: 'Emily Wilson',
  member1Alt: 'Image of John Doe',
  member5Alt: 'Image of Michael Lee',
  member3Job: 'Head of Cleaning Services',
  member6Src:
    'https://images.unsplash.com/photo-1506863530036-1efeddceb993?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM1OHw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Meet our team',
  member1Src:
    'https://images.unsplash.com/photo-1594877502388-8d9e1dfcd84b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM2MXw&ixlib=rb-4.0.3&q=80&w=1080',
  member5Job: 'Technical Supervisor',
  member6Alt: 'Image of Emily Wilson',
  member3Content:
    'David leads the cleaning team with expertise and dedication, ensuring top-notch service quality.',
  member1Job: 'Founder & CEO',
  member1Content:
    'John is the visionary leader behind Hydro Care Africa, with a passion for delivering exceptional exterior cleaning services.',
  member4: 'Sarah Brown',
  member4Src:
    'https://images.unsplash.com/photo-1636964886908-7b28097bc746?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTk1NDM1Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  actionContent: 'Open positions',
  member2Alt: 'Image of Jane Smith',
  member2Job: 'Operations Manager',
  member2Content:
    'Jane ensures smooth operations and customer satisfaction, bringing a wealth of experience in the service industry.',
  member6Content:
    'Emily handles marketing strategies and promotions, helping to spread the word about our exceptional services.',
  member4Content:
    'Sarah is dedicated to building strong customer relationships and ensuring client needs are met effectively.',
  content3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  content1: 'Join our team and make a difference in your career!',
}

MeetTheTeam.propTypes = {
  content2: PropTypes.string,
  member4Job: PropTypes.string,
  member2: PropTypes.string,
  member3Alt: PropTypes.string,
  member4Alt: PropTypes.string,
  member2Src: PropTypes.string,
  member3Src: PropTypes.string,
  member5Content: PropTypes.string,
  member6Job: PropTypes.string,
  heading2: PropTypes.string,
  member5Src: PropTypes.string,
  member5: PropTypes.string,
  member1: PropTypes.string,
  member3: PropTypes.string,
  member6: PropTypes.string,
  member1Alt: PropTypes.string,
  member5Alt: PropTypes.string,
  member3Job: PropTypes.string,
  member6Src: PropTypes.string,
  heading1: PropTypes.string,
  member1Src: PropTypes.string,
  member5Job: PropTypes.string,
  member6Alt: PropTypes.string,
  member3Content: PropTypes.string,
  member1Job: PropTypes.string,
  member1Content: PropTypes.string,
  member4: PropTypes.string,
  member4Src: PropTypes.string,
  actionContent: PropTypes.string,
  member2Alt: PropTypes.string,
  member2Job: PropTypes.string,
  member2Content: PropTypes.string,
  member6Content: PropTypes.string,
  member4Content: PropTypes.string,
  content3: PropTypes.string,
  content1: PropTypes.string,
}

export default MeetTheTeam
